.lightbox {
  z-index: 2000 !important;
  background: rgb(80, 80, 80, 0.75);
}

.header {
  position: fixed;
  cursor: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 2px 10px 20px;
  color: white;
  background-color: rgba(255, 0, 0, 0.4);
  min-height: 80px;
  width: 100%;
  transition: all 1s ease;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: auto;
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  max-height: 30%;
  height: fit-content;
  width: 100%;
  transition: all 1s ease;
  font-family: "Poppins", sans-serif;
  font-size: calc(0.5rem + 0.5vw);
}

.footer > h2 {
  font-weight: 600;
  font-size: calc(1.15rem + 0.5vw);
}

.footer > * {
  animation: fadeInAni 1s ease;
}

@media (max-width: 1200px) {
  .footer {
    font-size: calc(0.35rem + 0.5vw);
  }

  .footer > h2 {
    font-size: calc(0.85rem + 0.5vw);
  }
}

.hide {
  opacity: 0;
}

@keyframes fadeInAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  z-index: 2002;
  background: none;
  border-style: none;
  color: rgb(225, 225, 225, 0.5);
  font-size: 25px;
}

.close :hover {
  color: rgb(255, 255, 255, 0.8);
}

.nav-button {
  z-index: 10;
  background: none;
  border-style: none;
  font-size: 50px;
  cursor: pointer;
  padding: 0;
  margin: 1rem;
  color: #f5f5f5;
  transition: color 0.2s linear;
}

.nav-button :hover {
  color: rgb(80, 80, 80);
  background: none;
}

.right-arrow {
  position: absolute;
  right: 0;
}

.left-arrow {
  position: absolute;
  left: 0;
}
