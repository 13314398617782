.img-container {
  min-height: 800px;
}

.loading-container {
  max-height: 0;
  overflow: hidden;
}

.single-portfolio-content {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.single-portfolio-content img {
  width: 100%;
  transition: all 0.5s ease !important;
}
.hover-content {
  transition-duration: 500ms;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  z-index: 10;
}
.hover-content button {
  font-family: "Poppins", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 0;
  margin-left: -26px;
  z-index: 100;
  display: inline-block;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: none;
  background-color: #fc6060;
  text-align: center;
  line-height: 52px;
  font-size: 30px;
  color: #ffffff;
  text-decoration: none;
  transition-duration: 500ms;
}
.hover-content button:hover,
.hover-content button:focus {
  background-color: #914040;
  text-decoration: none;
}
.single-portfolio-content:hover img,
.single-portfolio-content:focus img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.single-portfolio-content:hover .hover-content,
.single-portfolio-content:focus .hover-content {
  opacity: 1;
  visibility: visible;
}
.single-portfolio-content:hover .hover-content button,
.single-portfolio-content:focus .hover-content button {
  margin-top: -26px;
}
