.headerDiv {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.headerDiv > h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.headerDiv > h3 {
    font-family: "Poppins", sans-serif;

}

.color-add {
  color: #fc6060;
}
