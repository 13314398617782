.section-padding {
  padding-top: 5rem;
  padding-bottom: 80px;
  text-align: center;
  align-items: center;
}

.headerDiv {
  position: relative;
  display: inline-block;
  width: fit-content; 
}

.headerDiv > h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.headerDiv .color-add {
  color: #fc6060;
}

.filter-selection-menu {
  position: relative;
  margin-bottom: 40px;
}

.portfolio-menu > button {
  position: relative;
  z-index: 1;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
  border-radius: 0;
  margin: 0 25px;
  transition-duration: 0.5s;
}
.portfolio-menu > button::after {
  transition-duration: 500ms;
  position: absolute;
  width: 0%;
  height: 3px;
  background-color: #fc6060;
  content: "";
  bottom: -5px;
  left: 0;
  right: 0;
  z-index: 1;
}
.portfolio-menu > button.active::after {
  width: 100%;
}
