#menu {
  padding: 15px;
  transition: all 0.8s;
}

.fill-background {
  background-color: #191919;
}

.transparent-background {
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 25%,  rgba(0, 0, 0, 0.15));
}

#menu a.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
#menu a.navbar-brand > span {
  color: #fc6060;
}

#menu .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 15px;
  font-weight: 1000;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
  overflow: hidden;
  background: linear-gradient(
    to right,
    #fc6060 0%,
    #fc6060 50%,
    #ffffff 50%,
    #ffffff 100%
  ); /* half color half white */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% 100%;
  background-position: 100% 100%;
  transition: background-position 0.2s ease;
  text-decoration: none;
  overflow: visible;
}

#menu .navbar-nav > li > a:hover {
  background-position: 150% 100%;
}

#menu .navbar-nav > li > a:after {
  display: block;
  position: relative;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #fc6060 0%, #ffb7b7 100%);
  content: "";
  transition: width 0.2s;
}
#menu .navbar-nav > li > a:hover:after {
  width: 100%;
}

#menu .navbar-nav > li > a.active,
#menu .navbar-nav > li > a.active:hover,
#menu .navbar-nav > li > a.active:focus {
  background: #fc6060;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% 100%;
  background-position: 150% 100%;
  transition: background-position 0.2s ease;
  text-decoration: none;
}
#menu .navbar-nav > li > a.active:after,
#menu .navbar-nav > li > a.active:after,
#menu .navbar-nav > li > a.active:after {
  display: block;
  position: relative;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background: #fc6060;
  content: "";
  transition: width 0.2s;
}
.navbar-toggler {
  transition: box-shadow 0.3s ease;
}
#menu .navbar-toggler:hover,
#menu .navbar-toggler:focus {
  outline: none;
  box-shadow: 0px 0px 0px 0.25rem;
}

#menu *:focus {
  outline: none;
}

.icon-header {
  background: none !important;
  border: none !important;
  transform: translateY(-2px);
  margin: 9px 20px 0;
  width: 20px;
}

.icon-div {
  width: fit-content;
  height: fit-content;
}

.icon-div:after {
  display: block;
  position: relative;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #fc6060 0%, #ffb7b7 100%);
  content: "";
  transition: width 0.2s;
}

.icon-div:hover:after {
  width: 100%;
}

.icon-colored {
  color: #fc6060;
  transition: color 0.5s ease;
}

.icon-white {
  color: white;
  transition: color 0.5s ease;
}

#logindiv {
  overflow: visible;
  width: fit-content;
  height: fit-content;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #191919;
  background-color: #f5f5f5;
  -webkit-text-fill-color: initial;
  overflow: hidden;
  text-decoration: none;
  overflow: visible;
  opacity: 0;
  transition: all 0.3s ease;
  box-shadow: 5px 5px 20px 1px rgba(15, 15, 15, 0.15);
}

#logindiv.show {
  opacity: 1;
  animation: fadeInAni 1s;
}

#logindiv .row {
  min-width: 300px;
}

#logindiv label {
  color: #191919 !important;
  text-decoration: none;
  background-clip: border-box;
}

#logindiv form > button {
  color: #f5f5f5 !important;
  background: #fc6060 !important;
  border: 2px solid #fc6060 !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  min-width: 60px;
}

#logindiv form > button:hover {
  background: transparent !important;
  /* border: 2px solid #fc6060 !important; */
  color: #fc6060 !important;
}

#logindiv form > .error {
  color: red;
  font-size: 10px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

#logindiv form > .hide {
  visibility: hidden;
  opacity: 0;
}

.logoutdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 300px;
}

.logoutdiv > button {
  color: #f5f5f5 !important;
  background: #777 !important;
  border: 2px solid #777 !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
}

.logoutdiv > button:hover {
  background: transparent !important;
  border: 2px solid #777 !important;
  color: #777 !important;
}

.logoutdiv > a {
  color: #f5f5f5 !important;
  background: #fc6060 !important;
  border: 2px solid #fc6060 !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  text-transform: none;
}
