.upload-img-main {
  padding-top: 5rem;
  padding-bottom: 80px;
  text-align: center;
  align-items: center;
  background-color: #f5f5f5;
  min-height: calc(100vh - 281px);
  font-family: "Poppins", sans-serif;
}

.headerDiv {
  position: relative;
  display: inline-block;
  width: fit-content;
}

.headerDiv > h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.headerDiv > h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.color-add {
  color: #fc6060;
}

.upload-div {
  display: flex;
  flex-direction: column;
  height: 320px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 200, 200, 0.5);
  border-radius: 8px;
  margin: 0.75rem;
}

.img-div {
  max-width: 100%;
  max-height: 100%;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.img-div img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.img-icon {
  color: rgba(240, 240, 240, 0.75);
  font-size: 3rem;
  cursor: pointer;
}

.form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e6e9;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.form-control:focus {
  border-color: #fc6060;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}

.form-control::-webkit-input-placeholder {
  color: #777;
}

label {
  text-align: left;
  padding: 0 !important;
}

.tag-select {
  text-align: left;
}

input::file-selector-button {
  color: #f5f5f5;
  background: rgb(25, 25, 25, 0.85);
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  width: 7rem;
  margin-right: 1rem;
  margin-left: 0;
  font-family: "Poppins", sans-serif;
}

input::file-selector-button:hover {
  background: transparent;
  border: 2px solid rgb(25, 25, 25, 0.85);
  color: rgb(25, 25, 25, 0.85);
}

@media (max-width: 992px) {
    .img-sel-button {
        margin-top: 20px;
    }
}

.submit-button {
  margin: 30px 0;
  color: #f5f5f5 !important;
  background: #fc6060 !important;
  border: 2px solid #f5f5f5 !important;
  width: 10rem;
  font-family: "Poppins", sans-serif !important;
}

.submit-button:hover {
  background: transparent !important;
  border: 2px solid #fc6060 !important;
  color: #fc6060 !important;
}

.cancel-button {
  margin: 30px 0;
  color: #f5f5f5 !important;
  background: #777 !important;
  border: 2px solid #f5f5f5 !important;
  width: 10rem;
  font-family: "Poppins", sans-serif !important;
}

.cancel-button:hover {
  background: transparent !important;
  border: 2px solid #777 !important;
  color: #777 !important;
}

