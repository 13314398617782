.footer-wrappper {
  position: relative;
  background: #191919;
  z-index: 100;
}

.footer-padding {
  padding-top: 50px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .footer-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.footer-area .main-menu2 {
  padding: 10px 0;
}

.footer-area .main-menu2 > span {
  display: inline-block;
  position: relative;
  font-family: "Poppins", sans-serif;
  color: #9da4ab;
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  text-transform: uppercase;
}

.footer-area .main-menu2 ul li a:hover {
  text-decoration: underline;
  color: #fff;
}

.footer-bottom-area .footer-border {
  padding: 33px 0px 20px;
}

.footer-bottom-area .footer-copy-right p {
  color: #646d77;
  font-weight: 300;
  font-size: 14px;
  line-height: 2;
  margin-bottom: 12px;
}

.footer-bottom-area .footer-copy-right p i {
  color: #fff;
}

.footer-bottom-area .footer-copy-right p a {
  color: #fff;
}

.footer-social {
  transform: translateX(5px);
  padding: 0.25rem 0.5rem;
}

.footer-social a {
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  margin-right: 8px;
  border: 1px solid #9da4ab;
}

.footer-social a:hover {
  color: #000;
  background: #fff;
  border: 1px solid transparent;
}

.footer-social a:hover i {
  transform: rotateY(180deg);
}

.footer-social a i {
  display: inline-block;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  transform: rotateY(0deg);
}

.footer-logo > a {
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #f5f5f5;
  text-transform: uppercase;
  text-decoration: none;
}
.footer-logo > a > span {
  color: #fc6060;
}
.footer-logo > a:hover {
  color: #f5f5f5;
}

.footer-copyright {
    display: inline-block;
    position: relative;
    font-family: "Poppins", sans-serif;
    color: #9da4ab;
    display: block;
    font-size: 12px;
    padding: 18px 26px 0px 26px;
}
