.GalleryMain {
  background-color: #f5f5f5;
}

.uploadLinkDiv {
  position: absolute;
  top: 8.5rem;
  right: 1rem;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 992px) {
    .uploadLinkDiv {
        top: 8.25rem;
    }
}

.uploadLinkDiv a:active {
  border: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.cta {
  position: relative;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}

.cta:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  align-self: center;
  margin: auto;
  border-radius: 24px;
  background: #fc6060;
  width: 48px;
  height: 48px;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}

.cta span {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.025rem;
  vertical-align: middle;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: #fc6060;
  opacity: 0;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}

.cta > .icon {
  position: relative;
  top: 0;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  color: #f5f5f5;
}

.cta > .icon {
    transform: translate(-5px, 1px);
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}

.cta:hover:before {
  width: 100%;
  background: #fc6060;
  opacity: 1;
}

.cta:hover span {
    color: #f5f5f5;
    opacity: 1;
}

.cta:hover > .icon {
    transform: rotate(180deg);
}
