.contact-form-area .section-title {
    margin-bottom: 20px;
  }
  
  .contact-form-area .section-title > p {
    font-size: 16px;
  }
  
  .contact-form-area h2 {
    color: #000;
    margin-top: 10px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  
  .contact-form-area form {
    padding-top: 20px;
  }
  
  .contact-form-area .text-danger {
    color: #cc0033;
    text-align: left;
  }
  
  .contact-form-area form > button {
    margin: 30px 0;
    color: #f5f5f5;
    background: #fc6060;
    border: 2px solid #f5f5f5;
  }
  
  .contact-form-area form > button:hover {
    background: transparent;
    border: 2px solid #fc6060;
    color: #fc6060;
  }
  
  .fadeout-success {
    opacity: 0;
    transition:opacity 1s ease-in-out;
    color: #fc6060;
  }
  
  .success-transition {
    opacity: 1;
    transition:opacity 5s ease-in-out;
    color: #fc6060;
    /* do fade show up then wait 5s fade out */
    animation: 1s fadeIn , 1s 5s fadeOut forwards; 
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #444;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e6e9;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
  
  .form-control:focus {
    border-color: #fc6060;
    outline: 0;
    -webkit-box-shadow: transparent;
    box-shadow: transparent;
  }
  
  .form-control::-webkit-input-placeholder {
    color: #777;
  }
  
  @media only screen and (max-width: 767px) {
    .contact-form-area h2 {
      font-size: 22px;
    }
  
    .contact-form-area .section-title > p {
      font-size: 14px;
    }
  }