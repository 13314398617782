.contact-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 281px);
}

.contact-area {
  flex: 1;
  padding: 10rem 0 60px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Poppins", sans-serif;
}

.contact-title {
  font-size: 56px;
}

.contact-title > span {
  color: #fc6060;
}

.contact-title:after {
  display: block;
  position: relative;
  left: 0;
  bottom: -1px;
  width: 50%;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 10px;
  background: linear-gradient(to right, #fc6060 0%, #ffb7b7 75%, #f5f5f5 100%);
  content: "";
}

.contact-info {
  margin-top: 1rem;
}

.contact-info > p {
  font-size: 14px;
  margin-bottom: 5px;
}

.contact-info .text-contact-info {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 500;
  word-wrap: break-word;
}

@media only screen and (max-width: 767px) {
  .contact-area-start .contact-title {
    font-size: 30px;
  }

  .contact-info > a {
    font-size: 14px;
  }

}
