.lazy-load-image-background.blur {
  opacity: 0;
  filter: blur(15px);
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  animation: fadeInAniLazy 0.3s 1s 1 ease-out forwards;
}

@keyframes fadeInAniLazy {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}